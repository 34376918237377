import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from '@mui/icons-material/Logout';

const Logout = () => {
        const { logout } = useAuth0();

        return (
            <LogoutIcon
                onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                style={{ boxShadow: "0 0 5px rgba(255, 255, 255, 0.3)", transition: "box-shadow 0.3s", borderRadius: "100%", padding: "3px", cursor: "pointer" }}
                onMouseOver={(e) => { e.target.style.boxShadow = "0 0 5px rgba(255, 255, 255, 0.3)" }}
                onMouseOut={(e) => { e.target.style.boxShadow = "none" }}
            >
                Log Out
            </LogoutIcon>
        );
};

export default Logout;