// ChatApp.js
import React, { useEffect, useState } from 'react';
import Sidebar from './components/SideBar';
import Chat from './components/Chat'; // This is the main chat component that includes MessageList and ChatInput
import './chat.css'; // Ensure the CSS is imported here
import axios from 'axios';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Navigate } from 'react-router-dom';
import { Typography } from '@mui/material';


const hostname = process.env.REACT_APP_BASE_URL

const ChatApp = () => {
const { isAuthenticated, user } = useAuth0();
const [userThreads, setUserThreads] = useState([ ...user[["permissions/threads"]]]);

  const [chats, setChats] = useState([]);


  const [selectedChatId, setSelectedChatId] = useState(userThreads.length > 0 ? userThreads[0].thread.id : null);
  const [messages, setMessages] = useState([]); // You would fetch messages based on the selected chat
  // const [userThreads, setUserThreads] = useState([]);
  useEffect(() => {
    //map through userThreads and setChats to userThreads
    const threadArray = userThreads.map((thread) => {
      return { id: thread.thread.id, title: "chat", snippet: thread.thread.id };
    });
    console.log("hello", threadArray, selectedChatId);
    setChats(threadArray);
    handleChatSelect(selectedChatId);
    if(userThreads.length === 1) {
      setSelectedChatId(userThreads[0].thread.id);
    }
    
  }, []);

  useEffect(() => {
    const threadArray = userThreads.map((thread) => {
      return { id: thread.thread.id, title: "chat", snippet: thread.thread.id };
    });
    setChats(threadArray);
  }, [userThreads]);

  const addThread = async (thread) => {
    console.log(thread)
    setUserThreads((prevThreads) => [...prevThreads, {"thread":thread}]);
    const response = await axios.post(`${hostname}/api/update-metadata`, {threadArray: [...userThreads, {"thread": thread}], userId: user.sub});
    setSelectedChatId(thread.id);
    handleChatSelect(thread.id);
    console.log(response);
    console.log("new threads now", userThreads);
  };

  const deleteThread = async (threadId) => {
    console.log("deleting thread", threadId);
    const threadArray = userThreads.filter((thread) => {
      return thread.thread.id !== threadId;
    });
    setUserThreads(threadArray);
    const response = await axios.post(`${hostname}/api/update-metadata`, {threadArray: threadArray, userId: user.sub});
    console.log(response);
    if(threadArray.length === 0) {
      setMessages([]);
      setSelectedChatId(null);}
    console.log("new threads now", userThreads);
  }

  const handleChatSelect = async (chatId) => {
    if (chatId === null) { setSelectedChatId(null); return null}
    if(userThreads.length === 0) { setSelectedChatId(null); return null}
    else{    setSelectedChatId(chatId);
    try {
      const response = await axios.get(`${hostname}/api/messages`, { params: { threadId: chatId } });
      if (response.data.messages === undefined) {
        console.log("No messages yet")
      }
      if (response.data.messages) {
        // map through messages and setMessages to messages
        const messagesArray = response.data.messages.map((message) => {
          return { text: message.content[0].text.value, isUser: message.role === "user" };
        });
        setMessages(messagesArray.reverse()); // Reverse the messagesArray
      }
    } catch (error) {
      console.error(error);
    }}
  };

 
  return (
    <div className="chat-app-container">
    {console.log("hello Hostname", hostname)}
    {user["permissions/assistants"].length === 0 ? null: 
      <Sidebar 
        addThread={addThread}
        chats={chats} 
        onChatSelect={handleChatSelect} 
        selectedChatId={selectedChatId} 
        deleteThread={deleteThread}
        userThreads={userThreads}
      /> }
          {user["permissions/assistants"].length === 0 ? <div className='no-assistant'><Typography align='center'> You have no assistant assigned. <br/> Please contact your administrator to assign an assistant.</Typography> </div>: 

      <Chat messageHistory={messages} selectedChatId={selectedChatId} userThreads={userThreads} /> }
    </div>
  );
};

export default ChatApp;
