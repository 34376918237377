// Sidebar.js
import React, { useEffect, useState } from 'react';
import '../chat.css';
import Logout from './Logout';
import NewThread from './NewThread';
import ClearIcon from '@mui/icons-material/Clear';

const SideBar = ({ chats, onChatSelect, selectedChatId, addThread, deleteThread, userThreads }) => {
  const [hoveredChatId, setHoveredChatId] = useState(null);

  const handleDelete = (event, id) => {
    event.stopPropagation();
    console.log(id);
    deleteThread(id);
    onChatSelect(null);

    // Find the first available chat
    const availableChat = chats.find(chat => chat.id !== id);
    if (availableChat) {
      onChatSelect(availableChat.id);
    }
  };

  const handleChatHover = (id) => {
    setHoveredChatId(id);
  };

  const handleChatLeave = () => {
    setHoveredChatId(null);
  };

  return (
    <div className="sidebar">
      {chats.map((chat, index) => (
        <div
          key={chat.id}
          className={`sidebar-item ${selectedChatId === chat.id ? 'active' : ''}`}
          onClick={() => onChatSelect(chat.id)}
          onMouseEnter={() => handleChatHover(chat.id)}
          onMouseLeave={handleChatLeave}
        >
          <div className='delete-thread' style={{ display: hoveredChatId === chat.id ? 'block' : 'none' }}>
            <ClearIcon onClick={(event) => handleDelete(event, chat.id)} />
          </div>
          <div className="sidebar-item-content">
            <div className="sidebar-item-title">{`Chat #${index + 1}`}</div> {/* Set the title as the index */}
            {/* <div className="sidebar-item-snippet">{chat.snippet}</div> */}
          </div>
        </div>
      ))}
      <div className='logout-bottom'>
        <Logout />
      </div>
      <div className='new-thread'>
        <NewThread addThread={addThread} />
      </div>
    </div>
  );
};

export default SideBar;
