import React, { useState } from 'react';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { AddCircleOutline } from '@mui/icons-material';

const NewThread = ({ addThread }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const hostname = process.env.REACT_APP_BASE_URL
    const handleCreateThread = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${hostname}/api/create-thread`);
            console.log(response.data.thread)

            const newThread = response.data.thread;
            addThread(newThread);
        } catch (error) {
            console.error('Error creating thread:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <AddIcon
            onClick={handleCreateThread}
            disabled={isLoading}
            style={{
                boxShadow: isHovered ? '0 0 5px 2px rgba(255, 255, 255, 0.3)' : 'none', borderRadius: '100%', padding: '2px', cursor: 'pointer',
                transition: 'box-shadow 0.3s ease-in-out',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        />
    );
};

export default NewThread;
