// Chat.js
import React, { useEffect, useState } from 'react';
import SideBar from './SideBar';
import MessageList from './MessageList';
import ChatInput from './ChatInput';
import '../chat.css';
import axios from 'axios';
import { Typography } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";

const Chat = ({messageHistory, selectedChatId, userThreads}) => {
  const hostname = process.env.REACT_APP_BASE_URL
  const mockUserData =  {

  }

  const [messages, setMessages] = useState(messageHistory);
  const [noThreads, setNoThreads] = useState(false);
  const { user, isLoading } = useAuth0();
  useEffect(() => {
    setMessages(messageHistory);
  }, [messageHistory]);

  const handleSendMessage = async (newMessage) => {
    if (newMessage.trim()) {
      const newMsg = { text: newMessage, isUser: true };
      setMessages((prevMessages) => [...prevMessages, newMsg]);
      console.log(newMsg)
    
      // If you're using a backend service to store messages, you should send the message to the backend here as well
      await axios.post(`${hostname}/api/send-message`, {threadId: selectedChatId, content: newMessage, assistant: user["permissions/assistants"]});
      handleGetResponse(newMsg);
    }
  };
  
  const handleGetResponse = async (newMsg) => {
    const getResponse = async () => {
      const response = await axios.get(`${hostname}/api/get-response`, { params: { threadId: selectedChatId } });
      const responseText = response.data.response;
      console.log(responseText);
      const botMsg = { text: responseText, isUser: false };
      
      setMessages((prevMessages) => [...prevMessages, botMsg]);
    }
    getResponse();
  };

useEffect(() => {

if (userThreads.length === 0 ) {
  console.log("user threads 1", userThreads)
  setNoThreads(true);}
if (selectedChatId === null) {
  console.log(selectedChatId)
    setNoThreads(true);}
else if(userThreads.length === 1) {
  setNoThreads(false)
  console.log(selectedChatId, "hello world")
  setMessages(messageHistory);
}
}, [userThreads, selectedChatId, messageHistory]);


  return (
    <div className="chat-container">
      {noThreads && <div className="no-threads"><Typography>No chats Selected! Create one or select one!</Typography></div>}
        { !noThreads ? <MessageList messages={messages}/> : null} 
{!noThreads ? <ChatInput onSendMessage={handleSendMessage} /> : null }
    </div>
  );
};

export default Chat;
