// ChatMessage.js
import React from 'react';
import ReactMarkdown from 'react-markdown'; // Import the library
import '../chat.css'; // Make sure to import the CSS file

const ChatMessage = ({ message, isUser }) => {
  const messageClass = isUser ? 'message user' : 'message bot';

  return (
    <div className={messageClass}>
      <ReactMarkdown className="message-content">{message}</ReactMarkdown>
    </div>
  );
};

export default ChatMessage;