import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { createBrowserRouter, RouterProvider, useRoutes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Logout from "./components/Logout";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  return isAuthenticated ? element : <Navigate to="/login" />;
};

const router = createBrowserRouter([
 
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/logout",
    element: <Login />,
  },
  {
    path: "/*",
    element: <ProtectedRoute element={<App />} />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain="b2bdevnet.us.auth0.com"
    clientId="KBdjkRvWTX212WP92KWfqhxNIbxIWU1c"
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <RouterProvider router={router} />
  </Auth0Provider>
);

reportWebVitals();
